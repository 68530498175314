import { call, put } from "redux-saga/effects";
import { api, notify, queryBuilder } from "services";
import { requestErrorHandler } from "utils";

import {
  ListInvoicesAction,
  IRequestListInvoicesAction,
} from "store/ducks/invoice";

export function* listInvoicesRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IRequestListInvoicesAction;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `/invoices?${queryString}`
    );

    const { data, meta } = responseBody;
    yield put(ListInvoicesAction.success(data, meta));

    if (onSuccess && data.length) {
      onSuccess(query);
    }
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    yield put(ListInvoicesAction.failure(errorMessage));
    notify("error", errorMessage);
  }
}
