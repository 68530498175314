import { IInvoiceStatus, IInvoiceStatusLabels } from 'interfaces/invoice';
import React from 'react';

import * as S from './styles';

interface IStatusBoxProps {
  status: IInvoiceStatus;
}

const statusIcon: Record<IInvoiceStatus, any> = {
  transito: <S.IconTransito />,
  'transito-atraso': <S.IconTransito />,
  'transito-sem-prazo': <S.IconTransito />,
  entregue: <S.IconEntregue />,
  'entregue-cliente': <S.IconEntregue />,
  'entregue-atraso': <S.IconEntregue />,
  'entregue-sem-prazo': <S.IconEntregue />,
  'sem-status': <S.IconNoStatus />,
  cancelada: <S.IconCancel />,
  devolucao: <S.IconUndo />,
  'chegou-cliente': <S.IconTransito />,
};
const StatusBox: React.FC<IStatusBoxProps> = ({ status }) => {
  return (
    <S.Container>
      <S.Label>Status</S.Label>
      <S.Value>{IInvoiceStatusLabels[status]}</S.Value>
      <S.BackgroundIcon status={status}>{statusIcon[status]}</S.BackgroundIcon>
    </S.Container>
  );
};

export default StatusBox;
