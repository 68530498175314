import React from "react";
import { AnimatePresence } from "framer-motion";
import { Switch, useLocation } from "react-router-dom";

import { GuestRoute } from "./GuestRoute";
import { PrivateRoute } from "./PrivateRoute";

import Invoices from "pages/Invoices";
import Intro from "pages/Intro";
import Login from "pages/Login";

export const Routes: React.FC = () => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <PrivateRoute path="/" exact component={Invoices} />
        <GuestRoute path="/intro" exact component={Intro} />
        <GuestRoute path="/login" exact component={Login} />
      </Switch>
    </AnimatePresence>
  );
};
