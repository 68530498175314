import { combineReducers } from 'redux';
import { auth } from './auth';
import { listInvoices, showInvoice } from './invoice';
import { lastSearch } from './search';
import { showDistance } from './distance';

const reducers = combineReducers({
  auth,
  listInvoices,
  showInvoice,
  lastSearch,
  showDistance,
});

export default reducers;
