import React, { useCallback } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";

import { AuthState } from "store/ducks/auth";
import { usePermissions } from "hooks";
import { notify } from "services";

type Props = RouteProps & {
  component: React.ComponentType<any>;
  [propName: string]: any;
};

export const PrivateRoute: React.FC<Props> = ({
  component: Component,
  hasAnyPermission = [],
  hasEveryPermission = [],
  ...rest
}) => {
  const {
    hasAnyPermission: _hasAnyPermission,
    hasEveryPermission: _hasEveryPermission,
  } = usePermissions();

  const auth = useSelector<RootStateOrAny, AuthState>((state) => state.auth);

  const canAccess = useCallback(() => {
    if (!auth.loggedIn) return false;

    if (hasAnyPermission.length >= 1) {
      const anyPermission = _hasAnyPermission(hasAnyPermission);
      if (!anyPermission) {
        notify("Error", "Acesso negado");
      }
      return anyPermission;
    } else if (hasEveryPermission.length >= 1) {
      const everyPermission = _hasEveryPermission(hasEveryPermission);
      if (!everyPermission) {
        notify("error", "Acesso negado");
      }
      return everyPermission;
    } else {
      return true;
    }
  }, [
    _hasAnyPermission,
    _hasEveryPermission,
    auth,
    hasAnyPermission,
    hasEveryPermission,
  ]);

  const renderComponent = useCallback(
    (props) => {
      if (canAccess()) {
        return <Component {...props} />;
      }

      return (
        <Redirect
          to={{
            pathname: "/intro",
          }}
        />
      );
    },
    [Component, canAccess]
  );

  return <Route {...rest} render={(props) => renderComponent(props)} />;
};
