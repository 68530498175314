import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { toast } from "react-toastify";
import "./config/ReactotronConfig";
import { store, persistor } from "./store";
import GlobalStyle from "./styles/global";
import { Routes } from "routes";
import { BrowserRouter } from "react-router-dom";

toast.configure();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/">
          <GlobalStyle />
          <Routes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
