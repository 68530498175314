import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { ListInvoicesAction, IListInvoicesState } from 'store/ducks/invoice';
import { LastSearchActions } from 'store/ducks/search';
import { TFilter } from 'interfaces/invoice';
import Header from './Header';
import LastSearch from './LastSearch';
import List from './List';
import Search from './Search';
import Details from './Details';
import * as S from './styles';

interface IQuerySearch {
  page?: number;
  limit?: number;
  search: string;
  searchFor: TFilter;
}

const Invoices: React.FC = () => {
  const dispatch = useDispatch();
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentWidth, setContentWidth] = useState<number>(0);
  const [selectedInvoice, setSelectedInvoice] = useState<number | null>(null);
  const [query, setQuery] = useState<IQuerySearch>({
    page: 1,
    limit: 50,
    search: '',
    searchFor: 'invoice',
  });

  const { data, loading } = useSelector<RootStateOrAny, IListInvoicesState>(
    (state) => state.listInvoices
  );

  const onSearchSuccess = useCallback(
    (data) => {
      const lastSearch = {
        date: new Date(),
        search: data.search,
        searchFor: data.searchFor,
      };
      dispatch(LastSearchActions.success(lastSearch));
    },
    [dispatch]
  );
  const handleSearchChange = useCallback(
    (data: IQuerySearch) => {
      setQuery((state) => ({
        ...state,
        search: data.search,
        searchFor: data.searchFor,
        page: 1,
      }));
      dispatch(
        ListInvoicesAction.request(
          {
            ...query,
            search: data.search,
            searchFor: data.searchFor,
            page: 1,
          },
          onSearchSuccess
        )
      );
    },
    [dispatch, onSearchSuccess, query]
  );

  const onCloseDetails = () => {
    setSelectedInvoice(null);
  };

  useEffect(() => {
    if (contentRef.current?.clientHeight) {
      setContentWidth(contentRef.current?.clientHeight);
    }
  }, []);

  return (
    <>
      <Details invoiceId={selectedInvoice} onClose={onCloseDetails} />
      <S.Container>
        <S.Content ref={contentRef}>
          <Header />
          <LastSearch onSearch={handleSearchChange} />
          <Search
            searchFor={query.searchFor}
            onSearch={handleSearchChange}
            loading={loading}
          />
        </S.Content>
        <S.GroupResult sub={contentWidth}>
          {data.length > 0 ? (
            <List invoices={data} onSelected={setSelectedInvoice} />
          ) : (
            <S.Wrapper>
              <S.IconBigSearch />
              <S.SearchTitle>
                Tente fazer uma pesquisa
                <span>Os resultados aparecerão aqui</span>
              </S.SearchTitle>
            </S.Wrapper>
          )}
        </S.GroupResult>
      </S.Container>
    </>
  );
};

export default Invoices;
