import styled from 'styled-components';
import logo from 'assets/images/logo.png';
import bigDoll from 'assets/images/bigDoll.png';
import { Colors, fonts } from 'styles';
import { PageContainer, PageContent, Button } from 'styles/styled-components';

export const Container = styled(PageContainer)`
  background-color: ${Colors.Gray100};
`;

export const Content = styled(PageContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
`;

export const Wrapper = styled.div``;

export const Logo = styled.img.attrs({ src: logo })`
  max-width: max-content;
  margin-bottom: 80px;
  z-index: 1;
`;

export const Doll = styled.img.attrs({ src: bigDoll })`
  position: absolute;
  bottom: -120px;
  right: -120px;
  z-index: 0;
`;

export const Title = styled.h1`
  width: 100%;
  max-width: 50%;
  font-family: ${fonts.GilroyLight};
  font-size: 30px;
  line-height: 36px;
  color: ${Colors.White};
  z-index: 1;

  span {
    font-family: ${fonts.GilroySemiBold};
  }
`;

export const AccessButton = styled(Button).attrs({ btStyle: 'orange' })`
  width: 100%;
  z-index: 1;
`;
