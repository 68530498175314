import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { IListInvoice } from "interfaces/invoice";
import { IPaginate } from "interfaces/common";

export interface IListInvoicesState {
  data: IListInvoice[];
  pagination: IPaginate | null;
  loading: boolean;
  error: string | null;
}

export interface IRequestListInvoicesAction {
  query?: Record<string, any>;
  onSuccess: Function;
}

export interface ISuccessListInvoicesAction {
  data: IListInvoice[];
  pagination: IPaginate;
}

export interface IFailureListInvoicesAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess"],
    success: ["data", "pagination"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "LIST_INVOICE_" }
);

const INITIAL_STATE: IListInvoicesState = {
  data: [],
  pagination: null,
  loading: false,
  error: null,
};

const request = (state: IListInvoicesState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: IListInvoicesState,
  action: ISuccessListInvoicesAction
) =>
  update(state, {
    data: { $set: action.data },
    pagination: { $set: action.pagination },
    loading: { $set: false },
  });

const failure = (
  state: IListInvoicesState,
  action: IFailureListInvoicesAction
) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const listInvoices = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ListInvoicesTypes = Types;
export const ListInvoicesAction = Creators;
