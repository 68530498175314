import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Colors } from 'styles';

export const PageContainer = styled(motion.main).attrs({
  exit: { opacity: 0 },
})`
  width: 100%;
  max-width: 1920px;
  min-height: 100vh;
  background-color: ${Colors.Gray05};
  position: relative;
  margin: 0 auto;
`;

export const PageContent = styled.section`
  width: 100%;
  padding: 48px 24px;
`;
