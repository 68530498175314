import { call, put } from 'redux-saga/effects';
import { api, notify } from 'services';
import { requestErrorHandler } from 'utils';

import {
  ShowInvoiceAction,
  IRequestShowInvoiceAction,
} from 'store/ducks/invoice';

export function* showInvoicesRequest(action: any) {
  try {
    const { id, onSuccess } = action as IRequestShowInvoiceAction;

    const { data } = yield call(api.get, `/invoices/${id}`);

    yield put(ShowInvoiceAction.success(data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    yield put(ShowInvoiceAction.failure(errorMessage));
    notify('error', errorMessage);
  }
}
