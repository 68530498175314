import styled, { css } from 'styled-components';
import { Colors, fonts } from 'styles';
import { X, DollarCircle } from 'styles/styled-components';

export { Loading } from 'styles/styled-components';

export const Container = styled.section`
  margin-top: 40px;
  background-color: #ffffff;
  border-radius: 32px 32px 0 0;
  padding: 0px 24px 40px 24px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  animation-name: show;
  animation-duration: 500ms;

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

interface IOverlay {
  isOpen: boolean;
}

export const Overlay = styled.div<IOverlay>`
  display: none;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  animation-name: show;
  animation-duration: 500ms;

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
      animation-play-state: running;

      ${Container} {
        animation-play-state: running;
      }
    `}
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 24px;
  border-radius: 5px 5px 0 0;
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Title = styled.h1`
  font-family: ${fonts.GilroyLight};
  font-size: 20px;
  color: ${Colors.Gray100};

  span {
    font-family: ${fonts.GilroySemiBold};
  }
`;

export const ButtonClose = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export const IconClose = styled(X).attrs({ size: 40 })`
  color: ${Colors.Orange};
`;

export const DatesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 24px;
  justify-content: space-between;
  column-gap: 24px;
  row-gap: 32px;
  margin-bottom: 32px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 144px;
`;

export const DateLabel = styled.span`
  display: block;
  font-family: ${fonts.GilroyRegular};
  font-size: 14px;
  color: ${Colors.Gray100};
  line-height: 16px;
  margin-bottom: 8px;
`;

export const DateValue = styled.h3`
  font-family: ${fonts.GilroyBold};
  font-size: 18px;
  line-height: 22px;
  color: ${Colors.Gray100};
`;

export const ActorBoxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  row-gap: 16px;
`;

export const PriceBox = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 16px;
  background-color: ${Colors.Gray100};
  position: relative;
  margin-bottom: 32px;
`;

export const PriceLabel = styled(DateLabel)`
  color: #fff;
`;

export const PriceValue = styled.h2`
  font-family: ${fonts.GilroyBold};
  font-size: 29px;
  color: #fff;
  margin-bottom: 12px;
`;

export const WeightLabel = styled.span`
  display: block;
  font-family: ${fonts.GilroyRegular};
  font-size: 12px;
  line-height: 14px;
  color: #ffffff80;
`;

export const IconDollar = styled(DollarCircle).attrs({ size: 32 })`
  color: #fff;
  position: absolute;
  bottom: 24px;
  right: 24px;
`;

export const InvoiceItems = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;
