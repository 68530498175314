import styled, { css } from 'styled-components';
import { Input } from 'components/Shared/Form';
import { Search, Loading } from 'styles/styled-components';
import { Colors, fonts } from 'styles';

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 4px;
  margin-bottom: 24px;
  overflow-x: auto;
`;

export const SearchInput = styled(Input)`
  input {
    padding-right: 48px;
  }
`;

interface IButton {
  active?: boolean;
}
export const Button = styled.button.attrs({ type: 'button' })<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid ${Colors.Gray50};
  color: ${Colors.Gray50};
  font-family: ${fonts.GilroySemiBold};
  font-size: 14px;
  width: 100%;
  &:first-child {
    min-width: 80px;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${Colors.White};
      background-color: ${Colors.Orange};
      border-color: ${Colors.Orange};
    `}
`;

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const SearchButton = styled.button.attrs({ type: 'submit' })`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 2;
`;

export const IconSearch = styled(Search).attrs({ size: 24 })`
  color: ${Colors.Gray100};
`;

export const ActivityIndicatior = styled(Loading)`
  color: ${Colors.Orange};
`;
