import { call, put } from 'redux-saga/effects';
import { api, notify, queryBuilder } from 'services';
import { requestErrorHandler } from 'utils';

import {
  ShowDistanceAction,
  IRequestShowDistanceAction,
} from 'store/ducks/distance';

export function* showDistance(action: any) {
  try {
    const { query = {}, onSuccess } = action as IRequestShowDistanceAction;
    const queryString = queryBuilder(query);
    const { data } = yield call(api.get, `google/distance?${queryString}`);

    yield put(ShowDistanceAction.success(data));

    if (onSuccess) {
      onSuccess(data);
    }
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    yield put(ShowDistanceAction.failure(errorMessage));
    notify('error', errorMessage);
  }
}
