import { createActions, createReducer } from 'reduxsauce';
import update from 'immutability-helper';
import { IInvoice } from 'interfaces/invoice';

const { Types, Creators } = createActions(
  {
    request: ['id', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['error'],
    reset: [],
  },
  { prefix: 'SHOW_INVOICE_' }
);

export interface IShowInvoiceState {
  data: IInvoice | null;
  loading: boolean;
  error: string | null;
}

export interface IRequestShowInvoiceAction {
  id: number;
  onSuccess?: Function;
  onFailure?: Function;
}
export interface ISuccessShowInvoiceAction {
  data: IInvoice;
}

export interface IFailureShowInvoiceAction {
  error: string;
}

const INITIAL_STATE: IShowInvoiceState = {
  data: null,
  loading: false,
  error: null,
};

const request = (state: IShowInvoiceState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: IShowInvoiceState, action: ISuccessShowInvoiceAction) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: IShowInvoiceState, action: IFailureShowInvoiceAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const showInvoice = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ShowInvoiceTypes = Types;
export const ShowInvoiceAction = Creators;
