import { IListInvoice } from 'interfaces/invoice';
import React from 'react';

import * as S from './styles';

interface IListProps {
  onSelected: Function;
  invoices: IListInvoice[];
}

interface IItemProps {
  onSelected: Function;
  invoice: IListInvoice;
}

const Item: React.FC<IItemProps> = ({ invoice, onSelected }) => {
  return (
    <S.ItemContainer onClick={() => onSelected(invoice.id)}>
      <S.BusinessLine>
        {invoice.businessLine?.activity_division || '---'}
      </S.BusinessLine>
      <S.Wrap className="fill">
        <S.InvoiceNumber>
          {invoice.ide_numero_nf}-{invoice.ide_serie}
        </S.InvoiceNumber>
        <S.ClientName>
          {invoice.client?.trade_name || invoice.client?.company_name}
        </S.ClientName>
      </S.Wrap>
      <S.Wrap>
        <S.Price>{invoice.total_valor_total_nf}</S.Price>
        <S.Location>
          {invoice.dest_nome_municipio}/{invoice.dest_uf}
        </S.Location>
      </S.Wrap>
    </S.ItemContainer>
  );
};
const List: React.FC<IListProps> = ({ invoices, onSelected }) => {
  return (
    <S.Container>
      {invoices.map((invoice) => (
        <Item key={invoice.id} invoice={invoice} onSelected={onSelected} />
      ))}
    </S.Container>
  );
};

export default List;
