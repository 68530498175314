import styled, { css } from 'styled-components';
import { Colors, fonts, StatusColors } from 'styles';
import { Block, Check, Truck, Minus, Undo } from 'styles/styled-components';
import { IInvoiceStatus } from 'interfaces/invoice';

export const Container = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 16px;
  background-color: ${Colors.Gray100};
  margin-bottom: 32px;
  position: relative;
  z-index: 1;
`;

export const Label = styled.span`
  display: block;
  font-family: ${fonts.GilroyRegular};
  font-size: 14px;
  color: #fff;
  line-height: 16px;
  margin-bottom: 8px;
`;

export const Value = styled.h2`
  font-family: ${fonts.GilroyBold};
  font-size: 26px;
  color: #fff;
  max-width: 85%;
`;

interface IStatus {
  status: IInvoiceStatus;
}

export const BackgroundIcon = styled.div<IStatus>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  position: absolute;
  right: 24px;
  bottom: 24px;

  ${({ status }) =>
    status &&
    css`
      background-color: ${StatusColors[status]};
    `}
`;

export const IconTransito = styled(Truck).attrs({ size: 22 })`
  color: white;
`;
export const IconEntregue = styled(Check).attrs({ size: 24 })`
  color: white;
`;
export const IconCancel = styled(Block).attrs({ size: 24 })`
  color: white;
`;
export const IconNoStatus = styled(Minus).attrs({ size: 24 })`
  color: white;
`;
export const IconUndo = styled(Undo).attrs({ size: 24 })`
  color: white;
`;
