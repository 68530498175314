import styled from 'styled-components';
import { Colors, fonts } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemContainer = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  margin-bottom: 32px;
  width: 100%;
`;

export const BusinessLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${Colors.Orange20};
  border-radius: 8px;
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  color: ${Colors.Orange};
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.fill {
    flex: 1;
  }
`;

export const InvoiceNumber = styled.div`
  font-family: ${fonts.GilroyBold};
  font-size: 16px;
  color: ${Colors.Gray100};
`;

export const ClientName = styled.div`
  font-family: ${fonts.GilroyRegular};
  font-size: 12px;
  color: ${Colors.Gray50};
`;

export const Price = styled.div`
  width: 100%;
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  text-align: right;
  color: ${Colors.Gray100};
`;

export const Location = styled.div`
  width: 100%;
  font-family: ${fonts.GilroyRegular};
  font-size: 12px;
  text-align: right;
  color: ${Colors.Gray50};
`;
