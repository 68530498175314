import React, { useRef, useCallback } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useValidation } from 'hooks';

import { AuthActions, AuthState } from 'store/ducks/auth';
import { Input } from 'components/Shared/Form';
import * as S from './styles';

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();

  const { loading } = useSelector<RootStateOrAny, AuthState>(
    (state) => state.auth
  );

  const onSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string().required('Obrigatório').email('Email inválido'),
          password: Yup.string().required('Obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });
        dispatch(AuthActions.loginRequest(data));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors]
  );
  return (
    <S.Container>
      <S.Content>
        <S.Header>
          <S.Title>Login</S.Title>
          <S.BackButton onClick={() => history.push('/')}>
            <S.IconBack />
          </S.BackButton>
        </S.Header>
        <Form ref={formRef} onSubmit={onSubmit}>
          <S.FormRow>
            <Input name="email" placeholder="E-mail" type="email" />
          </S.FormRow>
          <S.FormRow>
            <Input name="password" placeholder="Senha" type="password" />
          </S.FormRow>
          <S.FormFooter>
            <S.Button type="submit">
              {loading ? <S.Loading /> : 'Entrar'}
            </S.Button>
          </S.FormFooter>
        </Form>
      </S.Content>
    </S.Container>
  );
};

export default Login;
