import React from 'react';

import * as S from './styles';

interface IActorBox {
  label: string;
  value: string | undefined | null;
  location: string | undefined | null;
}
const ActorBox: React.FC<IActorBox> = ({ label, value, location }) => {
  return (
    <S.Container>
      <S.ActorLabel>{label}</S.ActorLabel>
      <S.ActorValue>{value || '---'}</S.ActorValue>
      <S.ActorCity>{location || '---'}</S.ActorCity>
    </S.Container>
  );
};

export default ActorBox;
