import { all, takeLatest } from 'redux-saga/effects';

// Types
import { AuthTypes } from 'store/ducks/auth';
import { ListInvoicesTypes, ShowInvoiceTypes } from 'store/ducks/invoice';
import { ShowDistanceTypes } from 'store/ducks/distance';

// Sagas
import {
  authRehydrateAccessToken,
  authLoginRequest,
  authLogoutRequest,
} from './auth';
import { listInvoicesRequest, showInvoicesRequest } from './invoices';
import { showDistance } from './distance';

export default function* rootSaga() {
  yield all([
    // auth
    takeLatest('persist/REHYDRATE', authRehydrateAccessToken),
    takeLatest(AuthTypes.LOGIN_REQUEST, authLoginRequest),
    takeLatest(AuthTypes.LOGOUT_REQUEST, authLogoutRequest),

    takeLatest(ListInvoicesTypes.REQUEST, listInvoicesRequest),
    takeLatest(ShowInvoiceTypes.REQUEST, showInvoicesRequest),

    takeLatest(ShowDistanceTypes.REQUEST, showDistance),
  ]);
}
