import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { ShowDistanceAction, IShowDistanceState } from 'store/ducks/distance';
import { IInvoice } from 'interfaces/invoice';

import * as S from './styles';

interface IDistanceProps {
  invoice: IInvoice;
}
const Distance: React.FC<IDistanceProps> = ({ invoice }) => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState<number>(0);
  const [positionToFinished, setPositionToFinished] = useState<number>(0);
  const [, setTruckDistance] = useState<number>(0);
  const [distanceToFinished, setDistanceToFinished] = useState<number>(0);

  const { loading } = useSelector<RootStateOrAny, IShowDistanceState>(
    (state) => state.showDistance
  );

  const onSuccess = useCallback(
    (data) => {
      const { distance, duration } = data;

      if (distance && duration && invoice.distance) {
        const distanceCompleted = Math.abs(invoice.distance - distance);
        console.log('distanceCompleted', distanceCompleted);
        if (distanceCompleted > 0) {
          const formattedDistance = Number(distanceCompleted.toFixed(2));

          setTruckDistance(formattedDistance);
        } else {
          setTruckDistance(0);
        }

        const formattedDistance = Number(distance.toFixed(2));
        setDistanceToFinished(formattedDistance);

        const position = Number(
          ((distanceCompleted * 100) / invoice.distance).toFixed(2)
        );
        console.log('position', position);

        if (position < 0) {
          setPosition(0);
        } else {
          setPositionToFinished(100 - position);
          setPosition(position);
        }
      }
    },
    [invoice]
  );

  const getDistance = useCallback(
    (origins: string, destinations: string) => {
      dispatch(
        ShowDistanceAction.request({ origins, destinations }, onSuccess)
      );
    },
    [dispatch, onSuccess]
  );

  const getOriginAndDestination = useCallback(() => {
    if (invoice) {
      if (invoice.delivery_date) {
        setPositionToFinished(0);
        setPosition(100);
      } else {
        if (invoice.geolocations.length > 0 && invoice.client.address_zipcode) {
          const lastGeolocation = invoice.geolocations[0];
          const cep = invoice.client.address_zipcode.replace(
            /^(\d{5})(\d{3})/,
            '$1-$2'
          );
          const { latitude, longitude } = lastGeolocation;
          const origins = `${latitude},${longitude}`;
          const destinations = `${invoice.dest_nome_pais || 'Brasil'}, ${cep}`;
          getDistance(origins, destinations);
        }
      }
    }
  }, [getDistance, invoice]);

  useEffect(() => {
    getOriginAndDestination();
  }, [getOriginAndDestination]);

  return (
    <S.Container>
      {loading && <S.Loading />}
      <S.IllustrationPin />
      <S.IllustrationTree2 />
      <S.IllustrationTree1 />
      <S.IllustrationPackages />
      <S.IllustrationBuildings />
      <S.IllustrationBg />
      <S.Truck position={position}>
        <S.IllustrationTruck />
      </S.Truck>
      <S.Line />
      <S.DistanceContainer>
        <S.BallTruck position={position} />
        {/* <S.LabelTruck position={position}>{truckDistance} km</S.LabelTruck> */}
        <S.BallStart />
        <S.LabelStart>0</S.LabelStart>
        <S.BallEnd />
        <S.LabelEnd>
          {invoice.distance ? `${invoice.distance} km` : '---'}
        </S.LabelEnd>
        <S.DistanceToFinishedLine position={positionToFinished}>
          <S.LabelPositionToFinished>
            {distanceToFinished} km
          </S.LabelPositionToFinished>
        </S.DistanceToFinishedLine>
        <S.DistanceLine />
      </S.DistanceContainer>
    </S.Container>
  );
};

export default Distance;
