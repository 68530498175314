import { createActions, createReducer } from 'reduxsauce';
import update from 'immutability-helper';

export interface IShowDistanceState {
  data: Record<string, any> | null;
  loading: boolean;
  error: string | null;
}

export interface IRequestShowDistanceAction {
  query?: Record<string, any>;
  onSuccess?: Function;
}

export interface ISuccessShowDistanceAction {
  data: Record<string, any>;
}

export interface IFailureShowDistanceAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ['query', 'onSuccess'],
    success: ['data'],
    failure: ['error'],
    reset: [],
  },
  { prefix: 'SHOW_DISTANCE_' }
);

const INITIAL_STATE: IShowDistanceState = {
  data: null,
  loading: false,
  error: null,
};

const request = (state: IShowDistanceState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: IShowDistanceState,
  action: ISuccessShowDistanceAction
) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (
  state: IShowDistanceState,
  action: IFailureShowDistanceAction
) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const showDistance = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ShowDistanceTypes = Types;
export const ShowDistanceAction = Creators;
