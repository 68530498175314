import styled from 'styled-components';
import { ChevronRight } from 'styles/styled-components';
import { Colors, fonts } from 'styles';

export const Container = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${Colors.Gray100};
  border-radius: 16px;
  margin-bottom: 32px;
  position: relative;
`;

export const Label = styled.span`
  display: block;
  font-family: ${fonts.GilroyRegular};
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.Gray20};
  margin-bottom: 8px;
`;

export const Title = styled.h2`
  font-family: ${fonts.GilroyBold};
  font-size: 29px;
  line-height: 35px;
  color: ${Colors.White};
  margin-bottom: 12px;
`;

export const SearchFor = styled.p`
  font-family: ${fonts.GilroyRegular};
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${Colors.Gray10};
`;

export const Button = styled.button.attrs({ type: 'button' })`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${Colors.Orange};
  position: absolute;
  right: 24px;
  bottom: 24px;
`;

export const IconChrevronLeft = styled(ChevronRight).attrs({ size: 20 })`
  color: ${Colors.White};
`;

export const CleanerButton = styled.button.attrs({ type: 'button' })`
  width: 24px;
  height: 6px;
  border-radius: 3px;
  background-color: ${Colors.Orange};
  position: absolute;
  right: 24px;
  top: 24px;
`;
