import styled from 'styled-components';
import { LogOutCircle } from 'styles/styled-components';
import { Colors, fonts } from 'styles';

export const Container = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  width: 100%;
  max-width: 90%;
  font-family: ${fonts.GilroyLight};
  font-size: 40px;
  line-height: 40px;
  color: ${Colors.Gray100};

  span {
    font-family: ${fonts.GilroySemiBold};
  }
`;

export const LogoutButton = styled.button.attrs({ type: 'button' })`
  display: flex;
`;

export const IconLogout = styled(LogOutCircle).attrs({ size: 32 })`
  color: ${Colors.Orange};
`;
