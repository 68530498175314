import styled from 'styled-components';
import { Colors, fonts } from 'styles';

export const Container = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background-color: ${Colors.Gray10}80;
`;

export const ActorLabel = styled.span`
  display: block;
  font-family: ${fonts.GilroyRegular};
  font-size: 14px;
  color: ${Colors.Gray100};
  line-height: 16px;
  margin-bottom: 8px;
`;

export const ActorValue = styled.h3`
  font-family: ${fonts.GilroyBold};
  font-size: 18px;
  line-height: 22px;
  color: ${Colors.Gray100};
  margin-bottom: 8px;
`;

export const ActorCity = styled(ActorLabel)`
  text-transform: uppercase;
  font-size: 12px;
`;
