export const requestErrorHandler = (error: any | Error) => {
  if (error?.response?.data) {
    const {
      message: errorMessage = 'Não foi possível realizar a sua requisição',
    } = error?.response?.data;
    return { errorMessage };
  }

  if (error instanceof Error) {
    return { errorMessage: error.message };
  }

  return { errorMessage: 'Não foi possível realizar a sua requisição' };
};
