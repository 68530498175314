import styled from 'styled-components';
import {
  LogOutCircle,
  PageContainer,
  PageContent,
  Search,
} from 'styles/styled-components';
import { Colors, fonts } from 'styles';
export { Button, FormRow, FormFooter } from 'styles/styled-components';

export const Container = styled(PageContainer)``;

export const Content = styled(PageContent)``;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  width: 100%;
  max-width: 90%;
  font-family: ${fonts.GilroyLight};
  font-size: 40px;
  line-height: 40px;
  color: ${Colors.Gray100};

  span {
    font-family: ${fonts.GilroySemiBold};
  }
`;

export const LogoutButton = styled.button.attrs({ type: 'button' })`
  display: flex;
`;

export const IconLogout = styled(LogOutCircle).attrs({ size: 32 })`
  color: ${Colors.Orange};
`;

interface IGroupResult {
  sub: number;
}
export const GroupResult = styled.div<IGroupResult>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${({ sub }) => `calc(100vh - ${sub || 0}px)`};
  background-color: ${Colors.White};
  border-radius: 40px 40px 0 0;
  box-shadow: -5px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 32px 24px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 188px;
  align-self: center;
  justify-self: center;
  margin-top: 60px;
`;

export const IconBigSearch = styled(Search).attrs({ size: 54 })`
  color: #42244a20;
  margin-bottom: 16px;
`;

export const SearchTitle = styled.h2`
  font-family: ${fonts.GilroySemiBold};
  font-size: 29px;
  text-align: center;
  color: #42244a20;

  span {
    display: block;
    font-size: 12px;
    margin-top: 8px;
  }
`;
