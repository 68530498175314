import React from 'react';
import { useHistory } from 'react-router';
import * as S from './styles';

const Intro: React.FC = () => {
  const history = useHistory();
  return (
    <S.Container>
      <S.Content>
        <S.Wrapper>
          <S.Logo />
          <S.Title>
            Consultar as notas fiscais ficou <span>simples</span> e inteligente!
          </S.Title>
        </S.Wrapper>
        <S.AccessButton onClick={() => history.push('/login')}>
          Acessar minha conta
        </S.AccessButton>
        <S.Doll />
      </S.Content>
    </S.Container>
  );
};

export default Intro;
