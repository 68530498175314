import { createActions, createReducer } from "reduxsauce";
import update from "immutability-helper";
import { TFilter } from "interfaces/invoice";

export interface ILastSearchState {
  data: {
    date: string;
    search: string;
    searchFor: TFilter;
  } | null;
}

interface ISuccessLastSearch extends ILastSearchState {}

const { Types, Creators } = createActions(
  {
    success: ["data"],
    reset: [],
  },
  { prefix: "SEARCH_" }
);

const INITIAL_STATE = {
  data: null,
};

const success = (state: ILastSearchState, action: ISuccessLastSearch) =>
  update(state, {
    data: { $set: action.data },
  });

const reset = () => INITIAL_STATE;

export const lastSearch = createReducer(INITIAL_STATE, {
  [Types.SUCCESS]: success,
  [Types.RESET]: reset,
});

export const LastSearchTypes = Types;
export const LastSearchActions = Creators;
