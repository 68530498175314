import styled from 'styled-components';
import { Colors, fonts } from 'styles';

export const InvoiceItem = styled.div`
  width: 100%;
  display: flex;
  column-gap: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 144px;
`;

export const InvoiceItemOrder = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Orange20};
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  line-height: 14px;
  color: ${Colors.Orange};
`;

export const ProductCode = styled.h5`
  font-family: ${fonts.GilroyBold};
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 4px;
`;

export const ProductDescription = styled.p`
  font-family: ${fonts.GilroyRegular};
  font-size: 12px;
  color: ${Colors.Gray50};
`;

export const InvoiceItemWeight = styled(ProductCode)``;
