import React, { useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { format } from "date-fns";
import { ILastSearchState, LastSearchActions } from "store/ducks/search";
import * as S from "./styles";

interface ILastSearchProps {
  onSearch: Function;
}
const LastSearch: React.FC<ILastSearchProps> = ({ onSearch }) => {
  const dispatch = useDispatch();

  const { data } = useSelector<RootStateOrAny, ILastSearchState>(
    (state) => state.lastSearch
  );

  const handleClean = useCallback(() => {
    dispatch(LastSearchActions.reset());
  }, [dispatch]);

  const handleSearch = useCallback(() => {
    if (data) {
      onSearch({ search: data.search, searchFor: data.searchFor });
    }
  }, [data, onSearch]);
  return (
    data && (
      <S.Container>
        <S.CleanerButton onClick={handleClean} />
        <S.Label>Última busca</S.Label>
        <S.Title>{data.search}</S.Title>
        <S.SearchFor>
          {format(new Date(data.date), "dd MMM yyyy")} - {data.searchFor}
        </S.SearchFor>
        <S.Button onClick={handleSearch}>
          <S.IconChrevronLeft />
        </S.Button>
      </S.Container>
    )
  );
};

export default LastSearch;
