import React from 'react';
import { peso } from 'utils';
import { IInvoiceItem } from 'interfaces/invoice';

import * as S from './styles';

interface IInvoiceItemProps {
  item: IInvoiceItem;
}
const InvoiceItem: React.FC<IInvoiceItemProps> = ({ item }) => {
  return (
    <S.InvoiceItem key={item.id}>
      <S.InvoiceItemOrder>{item.nitem_numero_item || '-'}</S.InvoiceItemOrder>
      <S.Column>
        <S.ProductCode>{item.prod_codigo_produto || '---'}</S.ProductCode>
        <S.ProductDescription>
          {item.prod_descricao_rpoduto || '---'}
        </S.ProductDescription>
      </S.Column>
      <S.InvoiceItemWeight>
        {peso(item.prod_quantidade_comercial) || '---'}{' '}
        {item.prod_unidade_comercial}
      </S.InvoiceItemWeight>
    </S.InvoiceItem>
  );
};

export default InvoiceItem;
